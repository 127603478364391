<template>
    <div class="home-container">
        <img class="banner" src="@A/images/banner.png" @click="qucikCreate()" />
        <div class="column-center content-box" style="margin-top: 80px" ref="diy" id="diy">
            <div class="content" style="width: 1200px" >
                <div class="upload-select row-between">
                    <div class="upload-left" v-show="!isCreate" >
                        <!-- <uploader>
                            <img slot="default" style="width: 520px;height: 679px;" src="@A/images/upload_picture.png" />
                        </uploader> -->
                        <el-upload
                            ref="upload"
                            v-show="!showUploadPicture"
                            :action="`${HTTP_URL}uploadFile?_model=src`"
                            :file-list="fileList"
                            list-type="picture-card"
                            :limit="1"
                            :data="{
                                cutSizeX: 413,
                                cutSizeY: 519,
                                _sign: md5(`lx0e@_model=src&_time=${new Date().getTime()}&cutSizeX=413&cutSizeY=519n0*32`),
                                _time: new Date().getTime()
                            }"
                            :multiple="false"
                            accept="image/png, image/jpeg, image/jpg"
                            style="width: 520px;"
                            :on-success="handleUploadSuccess"
                            :before-upload="beforeUpload"
                        >
                            <div slot="default" class="column">
                                <img  style="width: 520px;height: 679px;" src="@A/images/upload_picture.png" />
                                <div class="upload-btn row-center" style="font-size: 16px" >{{uploadText}}</div>
                            </div>
                        </el-upload>
                        <div v-show="showUploadPicture" v-loading="isHandle">
                            <div class="row-center" style="width: 520px;height: 679px">
                                <img style="max-width: 520px;max-height: 679px" :src="uploadImage" alt="" >
                            </div>
                            <div class="upload-btn row-center" style="font-size: 16px" @click="reUpload" >{{uploadText}}</div>
                        </div>
                        <!-- <van-uploader
                        v-model="fileList"
                        :max-count="1"
                        :preview-full-image="false"
                        style="width: 520px;height: 679px;"
                        accept="image/png, image/jpeg, image/jpg"
                        preview-size="520px"
                        :before-read="beforeRead"
                        :after-read="afterRead"
                        >
                            <img slot="default"  src="@A/images/upload_picture.png" />
                        </van-uploader>-->

                        <div class="upload-tips" style="font-size: 16px">照片上传后，右侧选择照片尺寸和底色，点击“开始生成证件照”即一键生成</div>
                    </div>
                    <div class="create-left" v-show="isCreate">
                        <div class="top-tips row-center">
                            当前为预览效果图，证件照下载后无水印
                        </div>
                        <div class="create-picture-container row">
                            <div id="picture-container">
                            </div>
                            <div class="picture-layout row wrap ml20" style="min-width: 257px;">
                                <div class="top-container column-between">
                                    <div class="top" style="height: 162px;"></div>
                                    <div class="bottom" style="height: 162px;"></div>
                                </div>
                                <div class="left">
                                    <div class="left-top mb20" style="width: 122px;height: 162px;">
                                        <img style="width: 100px;height: 140px;" :style="{'background': pictureBackground.length > 0 && (pictureBackground[0].type == 'img' ? 'url(' + pictureBackground[0].icon + ')' : pictureBackground[0].color)}" :src="imageShown" alt="">
                                    </div>
                                    <div class="left-bottom" style="width: 122px;height: 162px;">
                                        <img style="width: 100px;height: 140px;" :style="{'background': pictureBackground.length > 0 && (pictureBackground[0].type == 'img' ? 'url(' + pictureBackground[0].icon + ')' : pictureBackground[0].color)}" :src="imageShown" alt="">
                                    </div>
                                </div>
                                <div class="right ml20">
                                    <div class="right-top mb20" style="width: 122px;height: 162px;">
                                        <img style="width: 100px;height: 140px;" :style="{'background': pictureBackground.length > 0 && (pictureBackground[0].type == 'img' ? 'url(' + pictureBackground[0].icon + ')' : pictureBackground[0].color)}" :src="imageShown" alt="">
                                    </div>
                                    <div class="right-bottom" style="width: 122px;height: 162px;">
                                        <img style="width: 100px;height: 140px;" :style="{'background': pictureBackground.length > 0 && (pictureBackground[0].type == 'img' ? 'url(' + pictureBackground[0].icon + ')' : pictureBackground[0].color)}" :src="imageShown" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="picture-info">
                            <div class="info-row row-between">
                                <div class="row">
                                    <div class="info-label">
                                        照片规格：
                                    </div>
                                    <div class="muted info-content">{{selectedList.length > 0 && selectedList[0].title}}</div>
                                </div>
                                <div class="row">
                                    <div class="info-label">
                                        底色：
                                    </div>
                                    <div class="muted info-content">{{pictureBackground.length > 0 && pictureBackground[0].title}}</div>
                                </div>
                                <div class="row">
                                    <div class="info-label">
                                        分辨率：
                                    </div>
                                    <div class="muted info-content">{{selectedList.length > 0 && selectedList[0].dpi || 300}}DPI</div>
                                </div>
                            </div>
                            <div class="info-row" style="margin-top: 24px">
                                <div class="row">
                                    <div class="info-label">
                                        文件大小：
                                    </div>
                                    <div class="muted info-content">{{isCalc ? '计算中' : targetSize + "kb"}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="reload-btn row-center" style="margin-top: 112px" @click="reUpload">
                            重新上传
                        </div>
                    </div>
                    <div class="upload-right">
                        <div>
                            <div class="right-title row" style="color: #111;">照片尺寸</div>
                            <div class="size-container row wrap">
                                <div
                                class="size-box-item column-center"
                                :class="{'active': item.selected}"
                                @click="handleSizeClick(item, index)"
                                v-for="(item, index) in sizeList"
                                :key="index"
                                :style="{'cursor': isCreate ? 'not-allowed' : 'pointer'}"
                                >
                                    <div>{{item.title}}</div>
                                    <div class="mt20">{{item.size}}</div>
                                </div>
                                <div class="size-box-item column-center" :style="{'cursor': isCreate || btnCreating ? 'not-allowed' : 'pointer'}"  @click="showMoreDialog">
                                    <div>更多规格</div>
                                </div>
                            </div>
                        </div>
                        <div style="margin-top: 48px">
                            <div class="right-title row" style="color: #111;">照片底色</div>
                            <div class="color-container row">
                                <div
                                class="color-box-item"
                                @click="handleColorClick(item, index)"
                                :class="{'active': item.selected, 'white-border': item.border}"
                                v-for="(item, index) in colorList"
                                :key="index"
                                :style="{'cursor': isCreate || btnCreating ? 'not-allowed' : 'pointer'}"
                                >
                                    <div v-if="item.type == 'color'" :style="{'background-color': item.color}" style="height: 100%">
                                    </div>
                                    <img v-else style="height: 100%" src="@A/images/color-trans.png"/>
                                </div>
                            </div>
                        </div>
                        <div style="margin-top: 48px">
                            <div class="row-between">
                                <div class="right-title" style="color: #111;">一键美颜</div>
                                <van-switch v-model="isBeauty" :disabled="isCreate || btnCreating" size="24px" inactive-color="rgb(229,229,229)" />
                            </div>
                            <el-button class="create-btn white row-center" :loading="btnCreating" v-if="!isCreate" :disabled="fileList.length <= 0" :style="{'opacity': fileList && fileList.length > 0 ? '1' : '0.4'}" @click="$createCrditUnChange" >
                                {{btnCreating ? '正在制作证件照' : '开始生成证件照'}}
                            </el-button>
                            <div class="download-btn white row-center" v-else @click="downloadClick">
                                下载无水印证件照
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="column-center content-box"  style="margin-top: 120px">
            <div class="content">
                <div class="content-title">
                    AI处理快捷方便、安全隐私
                </div>
                <div class="step-container">
                    <img style="width: 1200px" src="@A/images/AI.png" />
                </div>
                <div class="quick-diy-btn row-center" @click="qucikCreate()">立即制作</div>
            </div>
        </div>
        <div class="column-center content-box" style="margin-top: 120px">
            <div class="content">
                <div class="content-title">
                    海量证件照在线制作、轻松处理各类证件照
                </div>
                <div class="sub-title">
                    随时可以快速制作工作照、形象照、签证、护照、入学照等各类生活工作所需的证件照。
                </div>
                <div class="credit-container">
                    <div class="picture-show-list row-center wrap" style="width: 1200px">
                        <div class="picture-item column-center" v-for="(item, index) in pictureList" :key="index">
                            <img :src="item.picture" alt="">
                            <div class="picture-desc">{{item.desc}}</div>
                        </div>
                    </div>
                </div>
                 <div class="quick-diy-btn row-center" @click="qucikCreate()">立即制作</div>
            </div>
        </div>
        <div class="column-center" style="margin-top: 120px">
            <div class="footer" style="width: 1200px;height: 200px">
                <div class="version" @click="beianClick" style="font-size: 12px;">
                    湘ICP备2022002400号 | 永州时讯科技有限公司
                </div>
            </div>
        </div>
        <!-- <div id="box1">
            <div id="test"></div>
        </div> -->
        <el-dialog :visible.sync="showSizeDialog" class="show-size-dialog" style="border-radius: 8px" >
            <div class="size-dialog-container">
                <div class="tab-view row-center">
                    <div class="tab-view-item row-center" style="font-size: 20px;" :class="{'active': tabView == 0}" @click="tabViewChange(0)">
                        常见寸照
                    </div>
                    <div class="tab-view-item row-center" style="font-size: 20px;" :class="{'active': tabView == 1}" @click="tabViewChange(1)">
                        各类证件照
                    </div>
                </div>
                <div class="usual-size-container" v-show="tabView == 0" >
                    <div class="size-box-list row wrap">
                        <div class="size-item column-center" style="font-size: 14px" @click="handleUsualSizeClick(item, index)" :class="{'active': item.selected}" v-for="(item, index) in sizeList" :key="index">
                            <div style="font-size: 14px">{{item.title}}</div>
                            <div class="mt20">{{item.size}}</div>
                        </div>
                    </div>
                </div>
                <div class="usual-size-container column-center" v-show="tabView == 1">
                    <div class="size-box-list row wrap">
                        <div class="size-item column-center" style="font-size: 14px" @click="handleCreditSize(item, index)" :class="{'active': item.selected}" v-for="(item, index) in creditSize" :key="index">
                            <div style="font-size: 14px">{{item.title}}</div>
                            <div class="mt20">{{item.size}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>
        <el-dialog :visible.sync="showPayDialog" class="show-pay-dialog" style="border-radius: 8px" width="448px" @close="onPayDialogClose">
            <div class="pay-dialog" >
                <div v-show="!paySuccess">
                    <div class="price" v-show="payStart">￥{{price}}</div>
                    <div class="tips">扫码支付成功后，自动下载无水印版证件照</div>
                    <div class="pay-btn-container row-center" v-show="!payStart">
                        <div class="pay-btn column-center" :class="{'active': payWay == 2}" @click="changePayWay(2)">
                            <img src="@A/images/icon_wechat.png" alt="">
                            <div>微信支付</div>
                        </div>
                        <div class="pay-btn column-center" style="margin-left: 16px;" :class="{'active': payWay == 3}" @click="changePayWay(11)">
                            <img src="@A/images/icon_alipay.png" alt="">
                            <div>支付宝支付</div>
                        </div>
                    </div>
                    <div class="column-center" style="margin-top: 32px" v-show="payStart">
                        <div v-loading="payQRCode ==''">
                            <vue-qr  :text="payQRCode" :size="160"></vue-qr>
                        </div>
                        <!-- <img v-loading style="width: 160px;height: 160px;" src="codeUrl" alt=""> -->
                        <div style="font-size: 14px;margin-top: 16px;">{{payWay == 2 ? '请使用微信扫一扫，扫描上方二维码支付' : '请使用支付宝扫一扫，扫描上方二维码支付'}}</div>
                        <el-button class="mt20" type="primary" @click="reChoose">重新选择支付方式</el-button>
                    </div>
                </div>
                <div class="pay-success" v-show="paySuccess">
                    <img style="width: 64px;height: 64px;margin-top: 58px" src="@A/images/paySuccess.png" alt="">
                    <div class="pay-text mt20">已成功支付，即将自动下载</div>
                    <el-button class="down-load-pay-btn" @click="downloadResultPicture">下载照片</el-button>
                    <div class="muted" style="font-size: 14px;margin-bottom: 40px">如浏览器未自动下载照片，请点击以上按钮</div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {getSetup, upload2Image, handleImage, createCrditUnChange, getPriceSetup, pay, searchOrder, operationLog} from '@API/app'
import {apkChannel, apkVersionName, phoneModel, apkVersionCode, apkId, phoneOsVersion, phoneOs} from '@U/versionConfig'
import {changeDpiDataUrl} from "changedpi"
import vueQr from 'vue-qr'
import {random_int_str} from '@U/utils'
import axios from 'axios'
import md5 from '@U/md5'
import JSMD5 from 'js-md5'
import { HTTP_URL } from '@/configs'
export default {
    data() {
        return {
            sizeList: [
                {
                    title: "一寸",
                    size: "25X35",
                    cutSizeX: 25,
                    cutSizeY: 35,
                    pxSizeX: 295,
                    pxSizeY: 413,
                    dpi: 300,
                    selected: true
                },
                {
                    title: "二寸",
                    size: "35X49",
                    cutSizeX: 35,
                    cutSizeY: 49,
                    pxSizeX: 413,
                    pxSizeY: 579,
                    dpi: 300,
                    selected: false
                },
                {
                    title: "小一寸",
                    size: "22X32",
                    cutSizeX: 22,
                    cutSizeY: 32,
                    pxSizeX: 260,
                    pxSizeY: 378,
                    dpi: 300,
                    selected: false
                },
                {
                    title: "小二寸",
                    size: "35X45",
                    cutSizeX: 35,
                    cutSizeY: 45,
                    pxSizeX: 413,
                    pxSizeY: 531,
                    dpi: 300,
                    selected: false
                },
                {
                    title: "大一寸",
                    size: "33X48",
                    cutSizeX: 33,
                    cutSizeY: 48,
                    pxSizeX: 390,
                    pxSizeY: 413,
                    dpi: 300,
                    selected: false
                },
                {
                    title: "大二寸",
                    size: "35X53",
                    cutSizeX: 35,
                    cutSizeY: 53,
                    pxSizeX: 413,
                    pxSizeY: 626,
                    dpi: 300,
                    selected: false
                },
                {
                    title: "三寸",
                    size: "55X84",
                    cutSizeX: 55,
                    cutSizeY: 84,
                    pxSizeX: 649,
                    pxSizeY: 991,
                    dpi: 300,
                    selected: false
                },
                {
                    title: "四寸",
                    size: "76X102",
                    cutSizeX: 76,
                    cutSizeY: 102,
                    pxSizeX: 898,
                    pxSizeY: 1205,
                    dpi: 300,
                    selected: false
                },
                {
                    title: "五寸",
                    size: "89X127",
                    cutSizeX: 89,
                    cutSizeY: 127,
                    pxSizeX: 1050,
                    pxSizeY: 1499,
                    dpi: 300,
                    selected: false
                },
            ],
            colorList: [
                {
                    title: '红色',
                    type: 'color',
                    color: "red",
                    selected: true,
                    border: false
                },
                {
                    title: '橙色',
                    type: 'color',
                    color: "#F48E22",
                    selected: false,
                    border: false
                },
                {
                    title: '天蓝',
                    type: 'color',
                    color: "#2287F4",
                    selected: false,
                    border: false
                },
                {
                    title: '蓝色',
                    type: 'color',
                    color: "#2254F4",
                    selected: false,
                    border: false
                },
                {
                    title: '白色',
                    type: 'color',
                    color: "#FFFFFF",
                    selected: false,
                    border: true
                },
                // {
                //     title: '透明',
                //     type: 'img',
                //     color: "#00000000",
                //     selected: false,
                //     border: false,
                //     icon: require("@A/images/color-trans.png")
                // }
            ],
            pictureList: [
                {
                    picture: require("@A/images/credit1.png"),
                    desc: "常规寸照"
                },
                {
                    picture: require("@A/images/credit2.png"),
                    desc: "职业照"
                },
                {
                    picture: require("@A/images/credit3.png"),
                    desc: "入学照"
                },
                {
                    picture: require("@A/images/credit4.png"),
                    desc: "半身照"
                },
                {
                    picture: require("@A/images/credit5.png"),
                    desc: "考试职称照"
                },
                {
                    picture: require("@A/images/credit6.png"),
                    desc: "其他证件照"
                },
            ],
            creditSize: [
                {
                    title: "普通话水平测试",
                    size: "33X48",
                    cutSizeX: 33,
                    cutSizeY: 48,
                    pxSizeX: 390,
                    pxSizeY: 567,
                    dpi: 300,
                    minFileSize: 30,
                    maxFileSize: 80,
                    selected: false
                },
                {
                    title: "英语四六级考试",
                    size: "12X16",
                    cutSizeX: 12,
                    cutSizeY: 16,
                    minFileSize: 0,
                    maxFileSize: 10,
                    pxSizeX: 144,
                    pxSizeY: 192,
                    dpi: 300,
                    selected: false
                },
                {
                    title: "英语四六级考试",
                    size: "33X48",
                    cutSizeX: 33,
                    cutSizeY: 48,
                    minFileSize: 20,
                    maxFileSize: 100,
                    pxSizeX: 144,
                    pxSizeY: 192,
                    dpi: 300,
                    selected: false
                },
                {
                    title: "英语四六级考试",
                    size: "25X33",
                    cutSizeX: 25,
                    cutSizeY: 33,
                    minFileSize: 0,
                    maxFileSize: 20,
                    pxSizeX: 295,
                    pxSizeY: 413,
                    dpi: 300,
                    selected: false
                },
                {
                    title: "成人自考（一寸）",
                    size: "25X35",
                    cutSizeX: 25,
                    cutSizeY: 35,
                    minFileSize: 0,
                    maxFileSize: 20,
                    pxSizeX: 295,
                    pxSizeY: 413,
                    dpi: 300,
                    selected: false
                },
                {
                    title: "国家公务员考试",
                    size: "35X45",
                    cutSizeX: 35,
                    cutSizeY: 45,
                    minFileSize: 30,
                    maxFileSize: 10000,
                    pxSizeX: 413,
                    pxSizeY: 531,
                    dpi: 300,
                    selected: false
                },
                {
                    title: "专升本考试",
                    size: "30X40",
                    cutSizeX: 30,
                    cutSizeY: 40,
                    minFileSize: 20,
                    maxFileSize: 30,
                    pxSizeX: 360,
                    pxSizeY: 480,
                    dpi: 300,
                    selected: false
                },
                {
                    title: "初级会计职称考试",
                    size: "25X35",
                    cutSizeX: 25,
                    cutSizeY: 35,
                    minFileSize: 10,
                    maxFileSize: 10000,
                    pxSizeX: 295,
                    pxSizeY: 413,
                    dpi: 300,
                    selected: false
                },
                {
                    title: "中级会计职称考试",
                    size: "25X35",
                    cutSizeX: 25,
                    cutSizeY: 35,
                    minFileSize: 30,
                    maxFileSize: 10000,
                    pxSizeX: 295,
                    pxSizeY: 413,
                    dpi: 300,
                    selected: false
                },
                {
                    title: "2021注册会计师证",
                    size: "15X19",
                    cutSizeX: 15,
                    cutSizeY: 19,
                    minFileSize: 2,
                    maxFileSize: 20,
                    pxSizeX: 178,
                    pxSizeY: 220,
                    dpi: 300,
                    selected: false
                },
                {
                    title: "大学生图像信息采集",
                    size: "41X54",
                    cutSizeX: 41,
                    cutSizeY: 54,
                    minFileSize: 30,
                    maxFileSize: 900,
                    pxSizeX: 480,
                    pxSizeY: 640,
                    dpi: 300,
                    selected: false
                },
                {
                    title: "简历照片（一寸）",
                    size: "25X35",
                    cutSizeX: 25,
                    cutSizeY: 35,
                    pxSizeX: 295,
                    pxSizeY: 413,
                    dpi: 300,
                    selected: false
                },
                {
                    title: "二级建造师证（二建）",
                    size: "25X35",
                    cutSizeX: 25,
                    cutSizeY: 35,
                    minFileSize: 30,
                    maxFileSize: 50,
                    pxSizeX: 295,
                    pxSizeY: 413,
                    dpi: 300,
                    selected: false
                },
                {
                    title: "医保证",
                    size: "26X32",
                    cutSizeX: 26,
                    cutSizeY: 32,
                    minFileSize: 15,
                    maxFileSize: 35,
                    pxSizeX: 358,
                    pxSizeY: 441,
                    dpi: 350,
                    selected: false
                },
                {
                    title: "团员证",
                    size: "22X32",
                    cutSizeX: 22,
                    cutSizeY: 32,
                    pxSizeX: 254,
                    pxSizeY: 372,
                    dpi: 300,
                    selected: false
                },
                {
                    title: "党员证",
                    size: "34X51",
                    cutSizeX: 34,
                    cutSizeY: 51,
                    minFileSize: 0,
                    maxFileSize: 300,
                    pxSizeX: 400,
                    pxSizeY: 600,
                    dpi: 300,
                    selected: false
                },
            ],
            selectedList: [],
            uploadText: "点击上传一张图片",
            // 是否美颜
            isBeauty: false,
            fileList: [],
            showSizeDialog: false,
            tabView: 0,
            showUploadPicture: false,
            uploadImage: '',
            koutuImage: "",
            // 展示处理中
            isHandle: false,
            pictureBackground: [],
            // 是否生成了证件照
            isCreate: false,
            // 按钮读取状态
            btnCreating: false,
            // 展示图片
            imageShown: "",
            imgSize: 0,
            showPayDialog: false,
            wxAppId: "",
            wxMchId: "",
            // 2 微信支付 11 支付宝支付
            payWay: '',
            phoneId: "",
            price: "",
            payQRCode: "",
            payStart: false,
            paySuccess: false,
            resultPicture: "",
            mOssPath: "",
            isDownLoad: false,
            targetSize: 0,
            isCalc: false,
            orderId: -1,
            HTTP_URL,
        }
    },
    components: {
        vueQr
    },
    mounted() {
        if(this.timer) {
            clearInterval(this.timer)
        }
        if(localStorage.getItem("phoneId")) {
            this.phoneId = localStorage.getItem("phoneId");
        }
        else {
            this.phoneId = 'PC_phoneID_' + random_int_str(6);
            localStorage.setItem("phoneId", this.phoneId)
        }
        localStorage.setItem("bd_vid", this.$route.query.bd_vid)
        this.bd_vid = this.$route.query.bd_vid || localStorage.getItem("bd_vid");
        console.log("bd_vid ==> ", this.bd_vid)
        this.pictureBackground = this.colorList.filter((item) => {
            return item.selected == true
        })
        this.$getSetup();
    },
    destroyed() {
        if(this.timer) {
            clearInterval(this.timer)
        }
    },
    methods: {
        md5: JSMD5,
        // 埋点
        $operationLog(data) {
            operationLog({
                ...data
            }).then(res => {

            })
        },
        $getSetup() {
            getSetup({
                apkChannel: localStorage.getItem("apkChannel") || apkChannel,
                apkVersionName,
                phoneModel,
                apkVersionCode,
                apkId,
                phoneOsVersion,
                phoneOs
            }).then(res => {
                if(res.success == "1") {
                    this.sizeList = res.obj.specificationPcRegular;
                    this.creditSize = res.obj.specificationPcMore;
                    this.sizeList.forEach((item, index) => {
                        item.pxSizeX = item.width_px;
                        item.pxSizeY = item.height_px;
                        item.minFileSize = item.file_size_min;
                        item.maxFileSize = item.file_size_max;
                        item.cutSizeX = item.width_mm;
                        item.cutSizeY = item.height_mm;
                        item.title = item.spec_name;
                        item.size = item.width_mm + "X" + item.height_mm
                        item.dpi = item.ppi;
                        if(index == 0) {
                            item.selected = true
                        }
                        else {
                            item.selected = false
                        }
                    });
                    this.creditSize.forEach((item, index) => {
                        item.pxSizeX = item.width_px;
                        item.pxSizeY = item.height_px;
                        item.minFileSize = item.file_size_min;
                        item.maxFileSize = item.file_size_max;
                        item.cutSizeX = item.width_mm;
                        item.cutSizeY = item.height_mm;
                        item.title = item.spec_name;
                        item.size = item.width_mm + "X" + item.height_mm
                        item.dpi = item.ppi;
                        item.selected = false
                    })
                    this.wxAppId = res.obj.wxAppId;
                    this.wxMchId = res.obj.wxMchId;
                }
            })
        },
        $getPriceSetup() {
            getPriceSetup({
                apkId,
                apkChannel: localStorage.getItem("apkChannel") || apkChannel,
                apkVersionCode,
                phoneOs,
                phoneModel,
                apkVersionName,
                phoneOsVersion,
            }).then(res => {
                if(res.success == "1") {

                }
            })
        },
        // 点击更多规格时弹窗
        showMoreDialog() {
            if(this.isCreate || this.btnCreating) {
                return;
            }
            this.showSizeDialog = true;
            this.$operationLog({
                btnId: "pc-more size",
                type: '0'
            })
        },
        // 切换各类证件照
        tabViewChange(tabView) {
            this.tabView = tabView
        },
        // 点击快速制作跳转到功能处
        qucikCreate() {
            this.$nextTick(() => {

                // window.scrollTo({
                //     top: 200
                // })
                document.getElementById("diy").scrollIntoView();
                this.$operationLog({
                    btnId: "pc-make photo",
                    type: '0'
                })
            })
        },
        // 尺寸选择
        handleSizeClick(sizeObj, index) {
            if(this.isCreate || this.btnCreating) {
                return
            }
            this.sizeList.forEach((item, idx) => {
                if(index == idx) {
                    item.selected = true;
                }
                else {
                    item.selected = false;
                }
            })
            this.selectedList = this.sizeList.filter((item, idx) => {
                return item.selected == true
            })
        },
        // 更多规格时的处理
        handleCreditSize(sizeObj, index) {
            if(this.isCreate || this.btnCreating) {
                return
            }
            this.creditSize.forEach((item, idx) => {
                if(index == idx) {
                    item.selected = true
                }
                else {
                    item.selected = false
                }
            })
            let filterList = this.creditSize.filter((item, idx) => {
                return idx == index;
            })
            filterList[0].isLast = true;

            this.sizeList.forEach(item => {
                item.selected = false;
            })
            // 是否替换
            let isSwitch = false
            for(let i = 0; i < this.sizeList.length; i ++) {
                if(this.sizeList[i].isLast) {
                    isSwitch = true
                }
            }
            if(isSwitch) {
                this.sizeList.pop();
                this.sizeList.push(filterList[0]);
            }
            else {
                this.sizeList.push(filterList[0]);
            }
            this.showSizeDialog = false;
        },
        // 点击常规寸照时的处理
        handleUsualSizeClick(item, index) {
            if(this.isCreate  || this.btnCreating) {
                return
            }
            this.sizeList.forEach((item, idx) => {
                if(index == idx) {
                    item.selected = true
                }
                else {
                    item.selected = false
                }
            })
            this.showSizeDialog = false
        },
        // 颜色选择
        handleColorClick(colorObj, index) {
            if(this.isCreate || this.btnCreating) {
                return;
            }
            this.colorList.forEach((item, idx) => {
                if(index == idx) {
                    item.selected = true;
                }
                else {
                    item.selected = false;
                }
            });
            this.pictureBackground = this.colorList.filter((item, idx) => {
                return idx == index
            })
        },
        // 上传图片前
        beforeUpload(file) {
            // console.log("file ==> ", file)
            // let extension1 = file.name.match(/\.\w+(?=$)/)
            // file.name = file.uuid + extension1[0];

            let testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
            const extension = testmsg === 'png'
            const extension2 = testmsg === 'jpg'
            const extension3 = testmsg === 'jpeg'
            if(!extension && !extension2 && !extension3) {
                this.$message({
                    message: '上传文件只能是 png、jpg、jpeg格式!',
                    type: 'error'
                });
                return extension || extension2 || extension3
            }
            this.imgSize = (file.size / 1024).toFixed(1);
            if(this.imgSize < 30) {
                this.$message({
                    message: '上传文件大小不能小于 30KB!',
                    type: 'error'
                });
                return  extension || extension2 || extension3 && imgSize
            }
            this.$operationLog({
                btnId: "pc-upload image",
                type: '0'
            })

        },
        // 图片上传成功处理
        handleUploadSuccess(res, file) {
            // mOssAbsolutePath
            this.uploadImage = res.obj
            this.showUploadPicture = true;
            console.log("uploadSuccess ==> ", file)
            this.mOssPath = file.url
            this.fileList.push(file)
            this.uploadText = "点击重新上传图片"
            // this.$handleImage();

        },
        reUpload() {
            this.uploadText = "点击上传一张图片";
            this.fileList = [];
            this.showUploadPicture = false;
            this.isCreate = false;
            this.paySuccess = false;
            this.payStart = false;
            this.isDownLoad = false;
            // 清除画布
            this.clearCanvas();
        },
        $handleImage() {
            this.isHandle = true
            handleImage({
                imgInPath: this.uploadImage
            }).then(res => {
                if(res.success == "1") {
                    this.koutuImage = res.obj;
                    this.isHandle = false;
                }
            }).catch(err => {
                this.showUploadPicture = false;
                this.isHandle = false;
                this.fileList = [];
                this.$refs.upload.clearFiles()
                this.uploadText = "点击上传一张图片"
            })
        },
        // 不换装
        $createCrditUnChange() {
            if(this.imgSize < 30) {
                this.$message.error("上传图片过小，不能低于30KB");
                return;
            }
            this.$operationLog({
                btnId: "pc-make finish",
                type: "0"
            })
            let pxSizeX = 0;
            let pxSizeY = 0;
            let sharp = 0;
            let smooth = 0;
            let white = 0
            if(this.sizeList.filter(item => {
                return item.selected
            }).length <= 0) {
                this.$message.error("请选择尺寸")
                return;
            }
            this.sizeList.forEach(item => {
                if(item.selected) {
                    pxSizeX = item.pxSizeX;
                    pxSizeY = item.pxSizeY;
                }
            });

            this.selectedList = this.sizeList.filter(item => {
                return item.selected == true
            })

            console.log("大小:", this.imgSize, "sizeList", this.selectedList)

            if(this.isBeauty) {
                sharp = 0.6;
                smooth = 0.6;
                white = 0.6;
            }
            this.btnCreating = true
            createCrditUnChange({
                cutSizeX: pxSizeX,
                cutSizeY: pxSizeY,
                sharp,
                smooth,
                white,
                imgInPath: this.uploadImage,
                apkChannel,
                bd_vid: this.bd_vid
            }).then(res => {
                if(res.success == "1") {
                    this.btnCreating = false;
                    this.imageShown = res.obj;
                    this.isCreate = true;
                    this.$operationLog({
                        btnId: "pc-preview photo",
                        type: "0"
                    })
                    this.createImage()
                    this.calcImage()
                }
                else {
                    console.log("file")
                    this.btnCreating = false;
                    this.fileList = [];
                    this.$refs.upload.clearFiles()
                }
            }).catch(err => {
                console.log("error ==> ", err)
                this.showUploadPicture = false;
                this.btnCreating = false;
                this.fileList = [];
                this.$refs.upload.clearFiles()
            })
        },
        // 下载按钮点击下单
        downloadClick() {
            this.showPayDialog = true;
        },
        // 订单查询
        $searchOrder(id) {
            if(this.isDownLoad) {
                return
            }
            searchOrder({
                id: id
            }).then(res => {
                if(res.success == "1") {
                    if(res.obj.status == 1) {
                        console.log(true)
                        this.paySuccess = true;
                        this.resultPicture = res.obj.uidFolder;
                        this.downloadPicture()
                        this.isDownLoad = true;
                        this.$operationLog({
                            btnId: "pc-payment suc",
                            type: "0"
                        })
                        if(this.timer) {
                            clearInterval(this.timer)
                        }
                    }
                }
            })
        },
        changePayWay(payway) {
            this.payWay = payway
            this.selectedList = this.sizeList.filter((item, idx) => {
                return item.selected == true
            })
            let extendJson = {
                bgColorName: this.pictureBackground[0].title,
                bgcolor: this.pictureBackground[0].color,
                clothId: "no_cloth",
                cutPicCode: "",
                goodsName: this.selectedList[0].title,
                goodsOutlineText: "规格：" + this.selectedList[0].pxSizeX + "X" + this.selectedList[0].pxSizeY + " " + this.selectedList[0].size,
                img_wm_url: "",
                mKeys: ["商品名称", "规格尺寸"],
                // mOssPath: this.mOssPath,
                // mOssAbsolutePath: this.uploadImage,
                // mOssAbsolutePath: this.koutuImage,
                mOssAbsolutePath: this.uploadImage,
                mSpecId: this.selectedList[0].spec_id,
                mSpecName: this.selectedList[0].title,
                pxSizeX: this.selectedList[0].pxSizeX,
                pxSizeY: this.selectedList[0].pxSizeY,
                dpi: this.selectedList[0].dpi
            }
            this.$operationLog({
                btnId: "pc-submit orders",
                type: "0"
            })
            // 获取价格
            getPriceSetup({
                apkId,
                apkChannel: localStorage.getItem("apkChannel") || apkChannel,
                apkVersionCode,
                phoneOs,
                phoneModel,
                apkVersionName,
                phoneId: this.phoneId,
                phoneOsVersion,
            }).then(ret => {
                if(ret.success == "1") {
                    this.price = ret.obj[0].price
                    this.payStart = true
                    pay({
                        apkChannel: localStorage.getItem("apkChannel") || apkChannel,
                        apkId,
                        type: 0,
                        apkVersionCode,
                        apkVersionName,
                        money: this.price,
                        payWay: this.payWay,
                        phoneId: this.phoneId,
                        phoneOs: phoneOs,
                        phoneModel,
                        phoneOsVersion,
                        wxAppId: this.wxAppId,
                        wxMchId: this.wxMchId,
                        bdCallbackUrl: this.bd_vid,
                        extendJson: JSON.stringify(extendJson)
                    }).then(res => {
                        if(res.success == "1") {
                            if(this.payWay == 2) {
                                this.payQRCode = res.sign.codeUrl;
                            }
                            else if(this.payWay == 11) {
                                // 支付宝支付
                                let path = res.html.match(/https:\/\/qr\.alipay\.com\/([a-z]|[0-9])*/)[0];
                                this.payQRCode = path;
                                console.log("QRCode", this.payQRCode)
                            }
                            this.orderId = res.obj.id;
                            if(this.timer) {
                                clearInterval(this.timer)
                            }
                            this.timer = setInterval(() => {
                                this.$searchOrder(this.orderId)
                            }, 1000)
                        }
                    })
                }
            })
        },
        createImage() {
            let pxSizeX = 0;
            let pxSizeY = 0;
            this.sizeList.forEach(item => {
                if(item.selected) {
                    pxSizeX = item.pxSizeX;
                    pxSizeY = item.pxSizeY;
                }
            });
            let color = ""
            this.colorList.forEach(item => {
                if(item.selected) {
                    if(item.type == 'img') {
                        color = "#00000000"
                    }
                    else {
                        color = item.color
                    }
                }
            })
            let canvas = null;
            if(document.getElementById("picture-preview")) {
                canvas = document.getElementById("picture-preview")
            }
            else {
                canvas = document.createElement('canvas')
            }
            canvas.setAttribute("id", "picture-preview")
            if(pxSizeX >= 400 && pxSizeX <= 600) {
                pxSizeX = pxSizeX * 0.7
                pxSizeY = pxSizeY * 0.7
            }
            if(pxSizeX >= 600 && pxSizeX <= 800) {
                pxSizeX = pxSizeX * 0.5
                pxSizeY = pxSizeY * 0.5
            }
            if(pxSizeX > 800 && pxSizeX <= 1000) {
                pxSizeX = pxSizeX * 0.3
                pxSizeY = pxSizeY * 0.3
            }
            if(pxSizeX > 1000) {
                pxSizeX = pxSizeX * 0.3
                pxSizeY = pxSizeY * 0.3
            }
            canvas.width = pxSizeX;
            canvas.height = pxSizeY;
            canvas.style = "background: url(" + require("@A/images/color-trans.png") + ")"
            let ctx = canvas.getContext('2d')

            ctx.fillStyle = color;
            ctx.fillRect(0, 0, pxSizeX, pxSizeY)
            let image = new Image();
            image.setAttribute('crossOrigin', "Anonymous")
            image.src = this.imageShown;
            // image.src = "http://oss.sh.shunmakeji.cn/upload/identification/waterm"

            image.width = pxSizeX;
            image.height = pxSizeY;
            console.log("image ==> ", image)
            image.onload = function() {
                document.querySelector("#picture-container").appendChild(canvas)
                ctx.drawImage(image, 0, 0, image.width, image.height);
                let dataURL = canvas.toDataURL('image/jpeg', 2.0);
                let dataURL300DPI = changeDpiDataUrl(dataURL, 300);
                // console.log("dataURL ==> ", dataURL)
            }
        },
        calcImage() {
            let that = this;
            this.isCalc = true;
            let pxSizeX = 0;
            let pxSizeY = 0;
            this.sizeList.forEach(item => {
                if(item.selected) {
                    pxSizeX = item.pxSizeX;
                    pxSizeY = item.pxSizeY;
                }
            });
            let color = ""
            this.colorList.forEach(item => {
                if(item.selected) {
                    if(item.type == 'img') {
                        color = "#00000000"
                    }
                    else {
                        color = item.color
                    }
                }
            })
            let canvas = document.createElement('canvas');
            let ctx = canvas.getContext('2d');
            canvas.width = pxSizeX;
            canvas.height = pxSizeY;
            let image = new Image();
            ctx.fillStyle = color;
            ctx.fillRect(0, 0, pxSizeX, pxSizeY)
            image.setAttribute('crossOrigin', "Anonymous")
            image.src = this.imageShown;
            image.width = pxSizeX;
            image.height = pxSizeY;
            image.onload = function() {
                ctx.drawImage(image, 0, 0, image.width, image.height);
                let dataURL = canvas.toDataURL('image/jpeg', 2.0);
                let dataURL300DPI = changeDpiDataUrl(dataURL, 300);
                that.targetSize = ((dataURL300DPI.split(",")[1].length - (dataURL300DPI.split(",")[1].length / 8) * 2) / 1024).toFixed(1)
                that.isCalc = false;
                // console.log("dataURL ==> ", dataURL)
            }
        },
        onPayDialogClose() {
            if(this.timer) {
                clearInterval(this.timer)
            }
        },
        clearCanvas() {
            let canvas = document.getElementById("picture-preview")
            // let ctx = canvas.getContext('2d')
            // ctx.clearReact(0, 0, canvas.getAttribute("width"), canvas.getAttribute("height"))
        },
        reChoose() {
            this.payStart = false;
            this.payWay = "";
        },
        downloadResultPicture() {
            this.$operationLog({
                btnId: "pc-download photo",
                type: "0"
            });
            this.downloadPicture()
        },
        downloadPicture() {
            let that = this;
            let pxSizeX = 0;
            let pxSizeY = 0;
            let title = "";
            let dpi = 300
            this.sizeList.forEach(item => {
                if(item.selected) {
                    pxSizeX = item.pxSizeX;
                    pxSizeY = item.pxSizeY;
                    title = item.title
                    dpi = item.dpi
                }
            });
            let color = ""
            this.colorList.forEach(item => {
                if(item.selected) {
                    if(item.type == 'img') {
                        color = "#00000000"
                    }
                    else {
                        color = item.color
                    }
                }
            })
            let picture_link = document.createElement('a');
            let canvas = document.createElement('canvas');
            let ctx = canvas.getContext('2d');
            canvas.setAttribute("id", "result-picture")
            canvas.width = pxSizeX;
            canvas.height = pxSizeY;
            let image = new Image();
            ctx.fillStyle = color;
            ctx.fillRect(0, 0, pxSizeX, pxSizeY)
            image.setAttribute('crossOrigin', "Anonymous")
            image.src = this.resultPicture;
            image.width = pxSizeX;
            image.height = pxSizeY;
            image.onload = function() {
                // document.querySelector("#picture-container").appendChild(canvas)
                ctx.drawImage(image, 0, 0, image.width, image.height);
                let dataURL = canvas.toDataURL('image/jpeg', 2.0);
                let dataURL300DPI = changeDpiDataUrl(dataURL, dpi)
                let resultBlob = that.dataURItoBlob(dataURL300DPI);
                that.uploadResult(new File([resultBlob], new Date().getTime() + '.jpg'))
                picture_link.href = dataURL300DPI;
                picture_link.download = title + ".jpg";
                picture_link.target = "_blank";
                picture_link.click();
                that.$operationLog({
                    btnId: "pc-download auto",
                    type: "0"
                })
            }
        },
        beianClick() {
            window.open("http://beian.miit.gov.cn")
        },
        /* base64转Blob对象 */
        dataURItoBlob(data) {
            let byteString;
            if(data.split(',')[0].indexOf('base64') >= 0) {
                byteString = atob(data.split(',')[1])
            }else {
                byteString = unescape(data.split(',')[1])
            }
            let mimeString = data
                .split(',')[0]
                .split(':')[1]
                .split(';')[0];
            let ia = new Uint8Array(byteString.length)
            for( let i = 0; i < byteString.length; i += 1) {
                ia[i] = byteString.charCodeAt(i)
            }
            return new Blob([ia], {type: mimeString})
        },
        uploadResult(file) {
            let params = new FormData();
            params.append("file", file);
            params.append('orderId', this.orderId);
            let time = new Date().getTime()
            params.append("_time", time);
            params.append("_sign", JSMD5(`lx0e@_time=${time}&orderId=${this.orderId}n0*32`));
            let picture_link = document.createElement('a');
            return new Promise((resolve) => {
                axios
                    .post("/v1/uploadOrderPic", params, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then(({ data }) => {
                        if (data.success == 1) {
                            // this.resultPicture = data.obj;
                            if(this.isiOS) {
                                // location.href = data.obj;
                            }
                            else {

                            }
                        } else {
                        }
                    }).catch(err => {

                    });
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.home-container {
    background-color: white;
    .banner {
        width: 100%;
        cursor: pointer;
    }
    .content-box {
        .content {
            background-color: white;
            .content-title {
                margin-bottom: 24px;
                font-size: 92px;
                font-weight: 600;
                color: #111;
            }
            .content-desc {
                color: #808080;
                font-size: 22px;
            }
            .upload-select {
                margin-top: 64px;
                .upload-left/deep/ {
                    border: 1px solid #e5e5e5;
                    padding: 80px 80px 40px;
                    .upload-btn {
                        background-color: $--color-primary;
                        color: white;
                        margin-top: 80px;
                        width: 100%;
                        height: 120px;
                        cursor: pointer;
                    }
                    .upload-tips {
                        margin-top: 48px;
                        color: #484848;
                    }
                    .el-upload--picture-card {
                        width: 100%;
                        height: 100%;
                        border: none;
                    }
                    .el-upload-list--picture-card {
                        .el-upload-list__item {
                            display: none;
                        }
                    }
                }
                .create-left {
                    position: relative;
                    padding: 280px 40px 80px 80px;
                    border: 1px solid #E5E5E5;
                    width: 100%;
                    .top-tips {
                        position: absolute;
                        width: 100%;
                        background-color: #FCF6E8;
                        color: #CF8A49;
                        top: 0;
                        left: 0;
                        height: 80px;
                        font-size: 32px;
                    }
                    .create-picture-container {
                        .picture-layout {
                            position: relative;
                            .top-container {
                                position: absolute;
                                top: 0;
                                bottom: 0;
                                width: 100%;
                                height: 100%;
                                .top {
                                    width: 100%;
                                    border-bottom: 1px solid #e5e5e5;
                                }
                                .bottom {
                                    width: 100%;
                                    border-top: 1px solid #e5e5e5;
                                }
                            }
                            .left {
                                border-right: 1px solid #e5e5e5;
                                .left-top {
                                    display: flex;
                                    flex-direction: row;
                                    justify-content: flex-end;
                                    align-items: flex-end;
                                    border-right: 1px solid #e5e5e5;
                                    border-bottom: 1px solid #e5e5e5;
                                }
                                .left-bottom {
                                    display: flex;
                                    flex-direction: row;
                                    justify-content: flex-end;
                                    align-items: flex-start;
                                    border-top: 1px solid #e5e5e5;
                                    border-right: 1px solid #e5e5e5;
                                }
                                .border-bottom-line {
                                    width: 100;
                                }
                            }
                            .right {
                                border-left: 1px solid #e5e5e5;
                                .right-top {
                                    display: flex;
                                    flex-direction: row;
                                    justify-content: flex-start;
                                    align-items: flex-end;
                                    border-left: 1px solid #e5e5e5;
                                    border-bottom: 1px solid #e5e5e5;
                                }
                                .right-bottom {
                                    display: flex;
                                    flex-direction: row;
                                    justify-content: flex-start;
                                    align-items: flex-start;
                                    border-left: 1px solid #e5e5e5;
                                    border-top: 1px solid #e5e5e5;
                                }
                            }
                        }
                    }
                    .picture-info {
                        margin-top: 96px;
                        border-top: 4px dashed #e5e5e5;
                        padding-top: 96px;
                        .info-row {
                            .info-label {
                                font-size: 32px;
                                line-height: 44px;
                                font-family: PingFangSC-Regular, PingFang SC;
                            }
                            .info-content {
                                font-size: 32px;
                                line-height: 44px;
                                font-family: PingFangSC-Regular, PingFang SC;
                            }
                        }
                    }
                    .reload-btn {
                        border: 1px solid $--color-primary;
                        color: $--color-primary;
                        font-size: 32px;
                        height: 120px;
                        border-radius: 8px;
                        cursor: pointer;
                    }
                }
                .upload-right {
                    margin-left: 144px;
                    width: 1200px;
                    .right-title {
                        font-size: 64px;
                        font-weight: 600;
                    }
                    .size-container {
                        margin-top: 64px;
                        gap: 90px;
                        .size-box-item {
                            background-color: #F6F8F9;
                            color: #484848;
                            font-size: 28px;
                            width: 200px;
                            height: 200px;
                            cursor: pointer;
                            border: 1px solid #00000000;
                        }
                        .active {
                            border: 1px solid $--color-primary;
                            background-color: #EBEFFF;
                            color: $--color-primary;
                        }
                    }
                    .color-container {
                        margin-top: 72px;
                        gap: 56px;
                        .color-box-item {
                            width: 128px;
                            height: 128px;
                            border: 1px solid #00000000;
                            padding: 8px;
                            cursor: pointer;
                        }
                        .white-border {
                            border: 1px solid #E5E5E5;
                        }
                        .active {
                            border: 1px solid $--color-primary;
                            position: relative;
                            &::after {
                                content: '';
                                background-image: url(../assets/images/true.png);
                                background-size: 100% 100%;
                                position: absolute;
                                width: 64px;
                                height: 64px;
                                left: 50%;
                                top: 50%;
                                transform: translate(-50%, -50%);
                            }
                        }
                    }
                    .create-btn {
                        // 可选 不可选为0.4透明度
                        background-color: $--color-primary;
                        width: 100%;
                        height: 200px;
                        font-size: 64px;
                        font-weight: 500;
                        font-family: PingFangSC-Medium, PingFang SC;
                        border-radius: 8px;
                        margin-top: 100px;
                        &:hover {
                            color: white;
                        }
                    }
                    .download-btn {
                        height: 200px;
                        font-size: 64px;
                        font-weight: 500;
                        margin-top: 100px;
                        border-radius: 8px;
                        cursor: pointer;
                        background-color: #21C87A;
                        font-family: PingFangSC-Medium, PingFang SC;
                    }
                }
            }
            .step-container {
                margin-top: 96px;
                .step-item {
                    .step-num {
                        border: 5px solid $--color-primary;
                        width: 80px;
                        height: 80px;
                        color: $--color-primary;
                        font-size: 40px;
                        border-radius: 50%;
                    }
                    .step-explain {
                        color: #808080;
                        margin-top: 24px;
                        font-size: 44px;
                        font-weight: 500;
                    }
                    &::after {

                    }
                }
            }
            .quick-diy-btn {
                height: 120px;
                background-color: $--color-primary;
                color: white;
                margin: 128px 1000px 0;
                font-size: 32px;
                border-radius: 8px;
                cursor: pointer;
            }
            .sub-title {
                color: #808080;
                font-size: 44px;
                margin-top: 48px;
            }
            .credit-container {
                margin-top: 86px;
                .picture-show-list {
                    gap: 96px 126px;
                    .picture-item {
                        .picture-desc {
                            margin-top: 48px;
                            font-size: 32px;
                            font-weight: 500;
                            color: #111;
                            font-family: PingFangSC-Medium, PingFang SC;
                        }
                    }
                }
            }
        }
    }
    .show-size-dialog/deep/ {
        .el-dialog {
            border-radius: 8px;
            .el-dialog__body {
                padding: 30px 70px 48px;
            }
        }
    }
    .size-dialog-container {
        .tab-view {
            padding: 0px 145px 0;
            gap: 48px;
            .tab-view-item {
                height: 96px;
                width: 400px;
                cursor: pointer;
                border: 1px solid #e5e5e5;
            }
            .active {
                background-color: $--color-primary;
                color: white;
            }
        }
        .usual-size-container {
            // margin-top: 64px;
            margin: 64px 0;
            .size-box-list {
                gap: 48px;
                .size-item {
                    background-color: #F6F8F9;
                    color: #484848;
                    width: 200px;
                    height: 200px;
                    cursor: pointer;
                    border: 1px solid #00000000;
                }
                .active {
                    border: 1px solid $--color-primary;
                    background-color: #EBEFFF;
                    color: $--color-primary;
                }
            }
        }
    }
    .show-pay-dialog/deep/ {
        .el-dialog {
            border-radius: 8px;
            .el-dialog__body {
                padding: 0 0 48px;
            }
        }
    }
    .pay-dialog {
        font-family: PingFangSC-Semibold, PingFang SC;
        .price {
            color: #E65351;
            font-size: 48px;
            font-weight: 600;
        }
        .tips {
            color: #484848;
            font-size: 28px;
            margin-top: 32px;
        }
        .pay-btn-container {
            margin-top: 48px;
            .pay-btn {
                height: 80px;
                // padding: 0 60px;
                width: 320px;
                height: 320px;
                border: 1px solid #E5E5E5;
                cursor: pointer;
            }
            .active {
                color: $--color-primary;
                border: 1px solid $--color-primary;
            }
        }
        .pay-success {
            .pay-text {
                font-weight: 500;
                font-size: 40px;
            }
            .down-load-pay-btn {
                margin-top: 160px;
                border: 1px solid $--color-primary;
                color: $--color-primary;
                margin-bottom: 32px;
                width: 320px;
            }
        }
    }
    .footer {
        border-top: 1px solid #E5E5E5;
        .version {
            color: #808080;
            margin-top: 48px;
            cursor: pointer;
        }
    }
}
</style>
